import moment from "moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import listaVazia from "../assets/icones/lista-vazia.svg";
import { executaRequisicao } from "../services/api";

import { Item } from "./Item";

/**
 * @param {String} data
 * Presume-se que o formato será um dos seguintes:
 * - DD/MM/yyyy
 * - yyyy-MM-DD
 *
 * Ao passar um desses formatos, sempre retornará a data no formato 'yyyy-MM-DD'
 * A função não irá funcionar corretamente para formatos diferentes dos citados
 */
const retornaFormatoPadrao = (data) => {
  if (data && data.includes("/")) {
    return moment(data, "DD/MM/yyyy").format("yyyy-MM-DD");
  } else if (data && data.includes("-")) {
    return moment(data, "yyyy-MM-DD").format("yyyy-MM-DD");
  } else {
    return null;
  }
};


export const Listagem = (props) => {
  const { tasks, retrieveTasksWithFilters, currentPage, pageSize } = props;

  const [showModal, setShowModal] = useState(false);

  // STATES DO CADASTRO
  const [erro, setErro] = useState("");
  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [expectCompletionDate, setExpectCompletionDate] = useState("");
  const [completedDate, setCompletedDate] = useState("");

  const selecionarTarefa = (task) => {
    setErro("");
    setId(task.id);
    setName(task.name);
    setExpectCompletionDate(task.expectCompletionDate);
    setCompletedDate(task.completedDate);
    setShowModal(true);
  };

  const atualizarTarefa = async () => {
    try {
      if (!name || !expectCompletionDate) {
        setErro("Favor informar nome e data de previsão");
        return;
      }
      const body = {
        name: name,
        expectCompletionDate: expectCompletionDate,
        completedDate: completedDate,
      };
      await executaRequisicao("tasks/" + id, "put", body);
      await retrieveTasksWithFilters();
      setName("");
      setExpectCompletionDate("");
      setCompletedDate("");
      setId(null);
      setShowModal(false);
    } catch (e) {
      console.log(e);
      if (e?.response?.data?.erro) {
        setErro(e.response.data.erro);
      } else {
        setErro(
          "Não foi possível atualizar a tarefa, fale com o administrador.",
        );
      }
    }
  };

  const excluirTarefa = async () => {
    try {
      if (!id) {
        setErro("Favor informar a tarefa a ser excluída");
        return;
      }

      await executaRequisicao("tasks/" + id, "delete");
      await retrieveTasksWithFilters();
      setName("");
      setExpectCompletionDate("");
      setCompletedDate("");
      setId(null);
      setShowModal(false);
    } catch (e) {
      console.log(e);
      if (e?.response?.data?.erro) {
        setErro(e.response.data.erro);
      } else {
        setErro("Não foi possível excluir a tarefa, fale com o administrador.");
      }
    }
  };

  return (
    <>
      <div
        className={
          "container-listagem " + (tasks && tasks.length > 0 ? "" : "vazia")
        }
      >
        {tasks && tasks.length > 0 ? (
          tasks
            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
            .map((task) => (
              <Item
                task={task}
                key={task.id}
                selecionarTarefa={selecionarTarefa}
              />
            ))
        ) : (
          <>
            <img src={listaVazia} alt="Nenhuma atividade encontrada" />
            <p>Você ainda não possui tarefas cadastradas!</p>
          </>
        )}
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="container-modal"
      >
        <Modal.Body>
          <p>Alterar uma tarefa</p> {erro && <p className="error">{erro}</p>}
          <input
            type="text"
            name="nome"
            placeholder="Nome da tarefa"
            className="col-12"
            value={name}
            onChange={(evento) => setName(evento.target.value)}
          />
          <input
            type="date"
            name="dataPrevisao"
            placeholder="Data de previsão de conclusão"
            className="col-12"
            value={retornaFormatoPadrao(expectCompletionDate)}
            onChange={(evento) => setExpectCompletionDate(evento.target.value)}
          />
          <input
            type="date"
            name="dataConclusao"
            placeholder="Data de conclusão"
            className="col-12"
            value={retornaFormatoPadrao(completedDate)}
            onChange={(evento) => setCompletedDate(evento.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="buttons col-12">
            <button onClick={atualizarTarefa}>Alterar</button>
            <span onClick={excluirTarefa}>Excluir tarefa</span>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
