import React, { useEffect, useState } from "react";
import moment from "moment";
import concluidoIcon from "../assets/icones/checked-blue.svg";
import naoConcluidoIcon from "../assets/icones/clock.svg";

export const Item = ({ task, selecionarTarefa }) => {
  const { completedDate, name, expectCompletionDate } = task;
  const isCompleted = Boolean(completedDate);
  const [isMobileLayout, setIsMobileLayout] = useState(useMobileLayout());

  useEffect(() => {
    const handleResize = () => {
      setIsMobileLayout(useMobileLayout());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleItemClick = () => {
    const updatedTask = {
      ...task,
      completedDate: isCompleted ? null : moment().format("YYYY-MM-DD"),
    };
    selecionarTarefa(updatedTask);
  };

  // Limita o nome da tarefa apenas na versão mobile
  const truncatedName = isMobileLayout ? (name.length > 30 ? `${name.slice(0, 30)}...` : name) : name;

  return (
    <div className={`container-item ${isCompleted ? 'completed' : ''}`} onClick={handleItemClick}>
      <div className="checkbox">
        <img src={isCompleted ? concluidoIcon : naoConcluidoIcon} alt="checkbox" />
      </div>
      <div className="content">
        <p>{truncatedName}</p>
        <span className={isCompleted ? 'completed-text' : 'expected-completion-text'}>{isCompleted ? `Concluído em: ${moment(completedDate).format("DD/MM/yyyy")}` : `Previsão de conclusão em: ${moment(expectCompletionDate).format("DD/MM/yyyy")}`}</span>
      </div>
    </div>
  );
};

// Função para verificar se é layout mobile
const useMobileLayout = () => {
  const mediaQuery = window.matchMedia("(max-width: 767px)");
  return mediaQuery.matches;
};
