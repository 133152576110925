import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import dotenv from 'dotenv';

import App from "./App";

dotenv.config({ path: '.env.production.local' });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);
