import "./styles/app.scss";

import { useState } from "react";
import { Route, Switch } from "react-router-dom";

import { Cadastro } from "./pages/Cadastro";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";

function App() {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken"),
  );

  const [cadastrar, setCadastrar] = useState(false);
  return (
    <Switch>
      {!cadastrar ? (
        !accessToken ? (
          <Route path="*">
            <Login
              setAccessToken={setAccessToken}
              setCadastrar={setCadastrar}
            />
          </Route>
        ) : (
          <Route path="*">
            <Home setAccessToken={setAccessToken} />
          </Route>
        )
      ) : (
        <Cadastro setCadastrar={setCadastrar} />
      )}
    </Switch>
  );
}

export default App;
