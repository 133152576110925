// Filtros.jsx

import React, { useState } from "react";
import filtro from "../assets/icones/filtro.svg";

export const Filtros = ({
  periodBefore,
  periodAfter,
  status,
  setPeriodBefore,
  setPeriodAfter,
  setStatus,
}) => {
  const [showFiltros, setShowFiltro] = useState(false);

  return (
    <div className="container-filtros">
      <div className="title">
        <span>Filtrar</span>
        <img
          src={filtro}
          alt="Filtrar tarefas"
          onClick={() => setShowFiltro(!showFiltros)}
        />
        <div className="form">
          <div>
            <label>Data prevista de conclusão de:</label>
            <input
              type="date"
              value={periodAfter}
              onChange={(evento) => setPeriodAfter(evento.target.value)}
            />
          </div>
          <div>
            <label>até:</label>
            <input
              type="date"
              value={periodBefore}
              onChange={(evento) => setPeriodBefore(evento.target.value)}
            />
          </div>
          <div className="line" />
          <div>
            <label>Status:</label>
            <select
              value={status}
              onChange={(evento) => setStatus(evento.target.value)}
            >
              <option value={"WAITING"}>Todas</option>
              <option value={"IN_PROGRESS"}>Ativas</option>
              <option value={"COMPLETED"}>Concluídas</option>
            </select>
          </div>
        </div>
      </div>
      {showFiltros === true && (
        <div className="filtrosMobile">
          <div>
            <label>Período de:</label>
            <input
              type="date"
              value={periodAfter}
              onChange={(evento) => setPeriodAfter(evento.target.value)}
            />
          </div>
          <div>
            <label>Período até:</label>
            <input
              type="date"
              value={periodBefore}
              onChange={(evento) => setPeriodBefore(evento.target.value)}
            />
          </div>
          <div>
            <label>Status:</label>
            <select
              value={status}
              onChange={(evento) => setStatus(evento.target.value)}
            >
              <option value={"WAITING"}>Todas</option>
              <option value={"IN_PROGRESS"}>Ativas</option>
              <option value={"COMPLETED"}>Concluídas</option>
            </select>
          </div>
        </div>
      )}
    </div>
  );
};
