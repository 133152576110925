import React from "react";
import { ReactComponent as PlusIcon } from "../assets/icones/plus.svg"; // Importe o ícone do botão de adicionar

import sairIcon from "../assets/icones/exit.svg";
import logo from "../assets/icones/logo-OpUPD-home.png";

export const Header = (props) => {
  const { showModal, sair } = props;
  const nomeCompleto = localStorage.getItem("name");
  const primeiroNome = nomeCompleto?.split(" ")[0] || "";

  return (
    <header className="container-header">
      <img className="logo" src={logo} alt="Logo da Empresa" />

      {/* Botão de adicionar tarefa */}
      <button className="add-task-btn" onClick={showModal}>
        <PlusIcon className="plus-icon" />
        Adicionar Tarefa
      </button>

      {/* Saudação e botão de sair para dispositivos móveis */}
      <div className="mobile">
        <span>{"Olá, " + primeiroNome}</span>
        <img className="sair" src={sairIcon} alt="Deslogar" onClick={sair} />
      </div>

      {/* Saudação e botão de sair para desktop */}
      <div className="desktop">
        <span>{"Olá, " + primeiroNome}</span>
        <img
          className="sair"
          src={sairIcon}
          alt="Deslogar"
          onClick={sair}
        />
      </div>
    </header>
  );
};
