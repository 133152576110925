import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { Filtros } from "../componentes/Filtros";
import { Footer } from "../componentes/Footer";
import { Header } from "../componentes/Header";
import { Listagem } from "../componentes/Listagem";
import { executaRequisicao } from "../services/api";

export const Home = (props) => {
  // STATES DA LISTA
  const [tasks, setTasks] = useState([]);

  // STATES DOS FILTROS
  const [periodBefore, setPeriodBefore] = useState("");
  const [periodAfter, setPeriodAfter] = useState("");
  const [status, setStatus] = useState("WAITING");

  // PAGINAÇÃO
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  useEffect(() => setCurrentPage(1), [periodBefore, periodAfter, status]);

  const max_page_number = Math.ceil(tasks.length / pageSize) || 1;

  if (currentPage > max_page_number) {
    setCurrentPage(max_page_number);
  }

  // STATE DE EXIBICAO DO MODAL
  const [showModal, setShowModal] = useState(false);

  // STATES DO CADASTRO
  const [erro, setErro] = useState("");
  const [name, setName] = useState("");
  const [expectCompletionDate, setExpectCompletionDate] = useState("");

  const retrieveTasksWithFilters = async () => {
    try {
      let filtros = "?status=" + status;

      if (periodBefore) {
        filtros += "&periodBefore=" + periodBefore;
      }

      if (periodAfter) {
        filtros += "&periodAfter=" + periodAfter;
      }

      const resultado = await executaRequisicao("tasks" + filtros, "get");
      if (resultado && resultado.data) {
        setTasks(resultado.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const salvarTarefa = async () => {
    try {
      if (!name || !expectCompletionDate) {
        setErro("Favor informar nome e data de previsão");
        return;
      }

      const body = { name: name, expectCompletionDate: expectCompletionDate };

      await executaRequisicao("tasks", "post", body);
      await retrieveTasksWithFilters();
      setName("");
      setExpectCompletionDate("");
      setShowModal(false);
    } catch (e) {
      console.log(e);
      if (e?.response?.data?.erro) {
        setErro(e.response.data.erro);
      } else {
        setErro(
          "Não foi possível cadastrar a tarefa, fale com o administrador.",
        );
      }
    }
  };

  /* eslint-disable */
  useEffect(() => {
    retrieveTasksWithFilters();
  }, [status, periodBefore, periodAfter]);
  /* eslint-enable */

  const sair = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    props.setAccessToken("");
  };

  return (
    <>
      <Header sair={sair} showModal={() => setShowModal(true)} />
      <Filtros
        periodBefore={periodBefore}
        periodAfter={periodAfter}
        status={status}
        setPeriodBefore={setPeriodBefore}
        setPeriodAfter={setPeriodAfter}
        setStatus={setStatus}
      />
      <Listagem
        tasks={tasks}
        retrieveTasksWithFilters={retrieveTasksWithFilters}
        currentPage={currentPage}
        pageSize={pageSize}
      />
      <div className="pagination">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Página Anterior
        </button>
        <span>Página {currentPage}</span>
        <span className="page-count">de {max_page_number}</span>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === max_page_number}
        >
          {" "}
          Próxima Página
        </button>
      </div>
      <Footer showModal={() => setShowModal(true)} />{" "}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="container-modal"
      >
        {" "}
        <Modal.Body>
          <p>Adicionar uma tarefa</p>
          {erro && <p className="error">{erro}</p>}{" "}
          <input
            type="text"
            name="nome"
            placeholder="Nome da tarefa"
            className="col-12"
            value={name}
            onChange={(evento) => setName(evento.target.value)}
          />
          <input
            type="text"
            name="dataPrevisao"
            placeholder="Data de previsão de conclusão"
            className="col-12"
            value={expectCompletionDate}
            onChange={(evento) => setExpectCompletionDate(evento.target.value)}
            onFocus={(evento) => (evento.target.type = "date")}
            onBlur={(evento) =>
              expectCompletionDate
                ? (evento.target.type = "date")
                : (evento.target.type = "text")
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="buttons col-12">
            <button onClick={salvarTarefa}>Salvar</button>
            <span
              onClick={() => {
                setShowModal(false);
                setErro("");
                setName("");
                setExpectCompletionDate("");
              }}
            >
              {" "}
              Cancelar
            </span>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
