import React, { useState } from "react";

import lock from "../assets/icones/lock.svg";
import logo from "../assets/icones/sign_up.svg";
import mail from "../assets/icones/mail.svg";
import user from "../assets/icones/user1.svg";
import { Input } from "../componentes/Input";
import { executaRequisicao } from "../services/api";

export const Cadastro = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msgErro, setMsgErro] = useState("");
  const [isLoading, setLoading] = useState(false);

  const voltar = () => props.setCadastrar(false);

  const executaCadastro = async (evento) => {
    try {
      evento.preventDefault();
      setLoading(true);
      setMsgErro("");

      const body = {
        name: name,
        email: email,
        password: password,
      };

      await executaRequisicao("users", "post", body);
      props.setCadastrar(false);
    } catch (e) {
      console.log(e);
      if (e?.response?.data?.erro) {
        setMsgErro(e.response.data.erro);
      } else {
        setMsgErro(
          "Não foi possível efetuar o cadastro, fale com o administrador.",
        );
      }
      setTimeout(() => {
        setMsgErro("");
      }, 3000);
    }
    setLoading(false);
  };

  return (
    <div className="container-login">
      <div className="error-message">{msgErro && <p>{msgErro}</p>}</div>
      <img src={logo} alt="Logo" className="logo" />
      <form>
        <Input
          srcImg={user}
          altImg={"Icone nome"}
          inputType="text"
          inputNameName="nome"
          inputPlaceholder="Informe seu nome de usuário"
          value={name}
          setValue={setName}
        />
        <Input
          srcImg={mail}
          altImg={"Icone email"}
          inputType="text"
          inputName="email"
          inputPlaceholder="Informe seu email"
          value={email}
          setValue={setEmail}
        />
        <Input
          srcImg={lock}
          altImg={"Icone senha"}
          inputType="password"
          inputName="senha"
          inputPlaceholder="Informe sua senha"
          value={password}
          setValue={setPassword}
        />

        <button onClick={executaCadastro} disabled={isLoading}>
          {isLoading === true ? "...Carregando" : "Cadastrar"}
        </button>
        <span className="interactive-message" onClick={voltar}>
          Acessar sua conta
        </span>
      </form>
    </div>
  );
};
